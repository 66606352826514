.primary-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.primary-layout .trigger:hover {
  color: #1890ff;
}

.primary-layout .logo {
  height: 32px;
  margin: 16px;
  display: flex;
}

.primary-layout .logo img {
  height: 32px;
}

.primary-layout .logo h1 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-left: 16px;
}

.primary-layout .ant-layout-sider-collapsed .logo {
  justify-content: center;
}

.primary-layout .ant-layout-sider-collapsed .logo h1 {
  display: none;
}

.page-header {
  background-color: #ffffff;
}

.header-box-shadow {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.header-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  padding: 0px;
  width: calc(100% - 256px);
}

.content-fixed {
  padding-top: 64px;
}

.table-fitlers .ant-calendar-picker {
  margin-right: 8px;
}

.table-fitlers .ant-select, .table-fitlers .ant-btn, .table-fitlers .ant-input {
  margin-right: 8px;
  margin-bottom: 16px;
}

.progress-loading-label {
  margin-left: 0.5rem;
}
