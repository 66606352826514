.layout {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-color: #003667;
  background-attachment: fixed;
  background-size: cover;
  background-image: url('./background.jpg');
}

.layout .auth-wrapper {
  padding: 2rem;
  background-color: #fafafa;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.layout .ant-row.ant-form-item:last-of-type {
  margin-bottom: 0;
}

.form {
  width: 400px;
}

.submitButton {
  width: 100%;
}
